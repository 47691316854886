<template>
  <TwoColumnFeatureBlock
    :header-class="headerClass"
    header-text="Other Weather Apps vs. OpenSnow "
  >
    <template #firstColumn>
      <FeatureCard
        v-bind="otherWeatherAppsFeatureCardProps"
      />
    </template>
    <template #secondColumn>
      <FeatureCard
        v-bind="openSnowFeatureCardProps"
      />
    </template>
  </TwoColumnFeatureBlock>
</template>

<script>
import { getWeatherAppFeatures } from '@@/components/FAQs/FaqUtils';

export default {
  name: 'OpenSnowFeatures',

  props: {
    headerClass: {
      type: String,
      default: 'tw-text-center tw-mb-4 tw-text-2xl tw-font-medium',
    },
  },

  computed: {
    openSnowFeatureCardProps() {
      return {
        title: 'OpenSnow',
        viewExamples: false,
        features: getWeatherAppFeatures(true),
      };
    },

    otherWeatherAppsFeatureCardProps() {
      return {
        title: 'Other Weather Apps',
        viewExamples: false,
        features: getWeatherAppFeatures(false),
      };
    },
  },
};
</script>
